// generated on build time, from script >> iac/scripts/build-environment-conf.sh

export const environment = {
  production: true,
  env: 'dev',
  baseUrl: 'https://api.dev.belender.net',
  domainUrl: 'https://backoffice.dev.belender.net',
  widgetUrl: 'https://widget.dev.belender.net'
};


