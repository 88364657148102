import { Injectable } from '@angular/core';
import { UtilsService } from './utils.service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import moment from 'moment';
import { FetchService } from './fetch.service';

@Injectable({
    providedIn: 'root'
})
export class PresentialService {

    firestoreSubscription;

    constructor(public utils: UtilsService, private http: HttpClient, private localFetch: FetchService) { 
        
    }

    getVintegrisUser(userID) {
        return new Promise((resolve, reject) => {
            this.localFetch.getFetch(environment.baseUrl + '/certificate/users-list/users/' + userID ).then(d => {
                if(d) {
                    resolve(d);
                } else {
                    reject(d)
                }
            }).catch(e => {
                reject(e);
            })
        });
    }

    emitCirbe(requestID) {
        return new Promise((resolve, reject) => {
            this.localFetch.getFetch(environment.baseUrl + '/certificate/requests/' + requestID + '/get-cirbe').then(d => {
                if(d) {
                    resolve(d);
                } else {
                    reject(d)
                }
            }).catch(e => {
                reject(e);
            })
        });
    }

    
    addSolicitud(obj) {
        return new Promise((resolve, reject) => {
            this.localFetch.postFileFetchWhitoutToken(environment.baseUrl + '/requests/known-client', obj).then(d => {
                if(d) {
                    resolve(d);
                } else {
                    reject(d)
                }
            }).catch(e => {
                reject(e);
            })
        });
    }

    addFiles(obj) {
        return new Promise((resolve, reject) => {
            this.localFetch.postFile(environment.baseUrl + '/requests/known-client/upload-files', obj).then(d => {
                if(d) {
                    resolve(d);
                } else {
                    reject(d)
                }
            }).catch(e => {
                reject(e);
            })
        });
    }

    deleteUser(requestID, userID) {
        
        return new Promise((resolve, reject) => {
            this.localFetch.postFetch(environment.baseUrl + `/certificate/delete-user/request/${requestID}/users/${userID}`, {}).then(d => {
                if(d) {
                    resolve(d);
                } else {
                    reject(d)
                }
            }).catch(e => {
                reject(e);
            })
        });
    }

    

    preRequest(obj) {
        return new Promise((resolve, reject) => {
            this.localFetch.postFetchWhitoutToken(environment.baseUrl + '/pre-request/known-client', obj).then(d => {
                if(d) {
                    resolve(d);
                } else {
                    reject(d)
                }
            }).catch(e => {
                reject(e);
            })
        });
    }

    uploadFile(o) {
        
        
        return new Promise((resolve, reject) => {
            this.localFetch.postFileFetchWhitoutToken(environment.baseUrl + '/utils/upload', o).then(d => {
                if(d) {
                    resolve(d);
                } else {
                    reject(d)
                }
            }).catch(e => {
                reject(e);
            })
        });
    }

    checkService(obj) {
        return new Promise((resolve, reject) => {
            this.localFetch.postFetchWhitoutToken(environment.baseUrl + '/requests/known-client/check-service', obj).then(d => {
                if(d) {
                    resolve(d);
                } else {
                    reject(d)
                }
            }).catch(e => {
                reject(e);
            })
        });
    }

    getEmittedCertificates(id) {
     
        return new Promise((resolve, reject) => {
            this.localFetch.getFetch(environment.baseUrl + '/certificate/requests/user/' + id + '/state-final').then(d => {
                if(d) {
                    resolve(d);
                } else {
                    reject(d)
                }
            }).catch(e => {
                reject(e);
            })
        });
    }

    getPowers(id) {
        let o = {
            funder_id: id
        }
        return new Promise((resolve, reject) => {
            this.localFetch.getFetch(environment.baseUrl + '/digital-power').then(d => {
                if(d) {
                    resolve(d);
                } else {
                    reject(d)
                }
            }).catch(e => {
                reject(e);
            })
        });
    }

    getToValidate(id) {
        let o = {
            funder_id: id
        }
        return new Promise((resolve, reject) => {
            this.localFetch.getFetch(environment.baseUrl + '/requests/identity-validation/funders/' + id).then(d => {
                if(d) {
                    resolve(d);
                } else {
                    reject(d)
                }
            }).catch(e => {
                reject(e);
            })
        });
    }

    setPassword(obj) {
        return new Promise((resolve, reject) => {
            this.localFetch.postFetch(environment.baseUrl + '/requests/known-client/password', obj).then(d => {
                if(d) {
                    resolve(d);
                } else {
                    reject(d)
                }
            }).catch(e => {
                reject(e);
            })
        });
    }

    approve(obj) {
        return new Promise((resolve, reject) => {
            this.localFetch.postFetch(environment.baseUrl + '/requests/identity-validation/natural', obj).then(d => {
                if(d) {
                    resolve(d);
                } else {
                    reject(d)
                }
            }).catch(e => {
                reject(e);
            })
        });
    }

    validPower(obj) {
        return new Promise((resolve, reject) => {
            this.localFetch.postFetch(environment.baseUrl + '/requests/identity-validation/legal', obj).then(d => {
                if(d) {
                    resolve(d);
                } else {
                    reject(d)
                }
            }).catch(e => {
                reject(e);
            })
        });
    }

    sendJob(obj) {
        
        return new Promise((resolve, reject) => {
            this.localFetch.postFetch(environment.baseUrl + '/requests/known-client/send-job', obj).then(d => {
                if(d) {
                    resolve(d);
                } else {
                    reject(d)
                }
            }).catch(e => {
                reject(e);
            })
        });
    }

    requestDocuments(requestId) {
        
        return new Promise((resolve, reject) => {
            this.localFetch.getFetch(environment.baseUrl + '/requests/' + requestId + '/documents').then(d => {
                if(d) {
                    resolve(d);
                } else {
                    reject(d)
                }
            }).catch(e => {
                reject(e);
            })
        });
    }

    emitCertificate(obj) {
        return new Promise((resolve, reject) => {
            this.localFetch.postFetch(environment.baseUrl + '/certificate/emition', obj).then(d => {
                if(d) {
                    resolve(d);
                } else {
                    reject(d)
                }
            }).catch(e => {
                reject(e);
            })
        });
    }

    acceptTerms(obj) {
        return new Promise((resolve, reject) => {
            this.localFetch.postFetchWhitoutToken(environment.baseUrl + '/requests/known-client/terms', obj).then(d => {
                if(d) {
                    resolve(d);
                } else {
                    reject(d)
                }
            }).catch(e => {
                reject(e);
            })
        });
    }

    getAcceptanceFile() {
        return new Promise((resolve, reject) => {
            this.localFetch.getFetch(environment.baseUrl + '/requests/known-client/acceptance-file').then(d => {
                if(d) {
                    resolve(d);
                } else {
                    reject(d)
                }
            }).catch(e => {
                reject(e);
            })
        });

    }

    getValidationDocument(docID) {
        return new Promise((resolve, reject) => {
            this.localFetch.getFetch(environment.baseUrl + '/requests/identity-validation/document/' + docID).then((response: any) => {
                resolve(response);
           
            }).catch(e => {
                reject(e);
            })
        });
    }

    requestNote(requestId) {
        let o = {
            request_id: requestId
        }
        
        return new Promise((resolve, reject) => {
            this.localFetch.postFetch(environment.baseUrl + '/note-simple/requests/' + requestId, o).then((response: any) => {
                resolve(response);
                
           
            }).catch(e => {
                reject(e);
            })
        });
    }

    saveSimpleNote(obj) {
        return new Promise((resolve, reject) => {
            this.localFetch.putFetch(environment.baseUrl + '/note-simple' , obj).then((response: any) => {
                resolve(response);
           
            }).catch(e => {
                reject(e);
            })
        });
    }

 

    getValidationDocuments(request_id) {
        return new Promise((resolve, reject) => {
            this.localFetch.getFetch(environment.baseUrl + '/requests/' + request_id + '/identity-validation/documents').then(d => {
                if(d) {
                    resolve(d);
                } else {
                    reject(d)
                }
            }).catch(e => {
                reject(e);
            })
        });

    }

    retryRequest(obj) {
        return new Promise((resolve, reject) => {
            this.localFetch.postFetchWhitoutToken(environment.baseUrl + '/requests/known-client/retry', obj).then(d => {
                if(d) {
                    resolve(d);
                } else {
                    reject(d)
                }
            }).catch(e => {
                reject(e);
            })
        });
    }

    fixData(request_id, obj) {
        return new Promise((resolve, reject) => {
            this.localFetch.putFetchWhitoutToken(`${environment.baseUrl}/requests/${request_id}/person`, obj).then(d => {
                if(d) {
                    resolve(d);
                } else {
                    reject(d)
                }
            }).catch(e => {
                reject(e);
            })
        });
    }


    sendLinkBySms(requestID, obj) {
        return new Promise((resolve, reject) => {
            this.localFetch.postFetch(`${environment.baseUrl}/requests/${requestID}/send-link-sms`, obj).then(d => {
                if(d) {
                    resolve(d);
                } else {
                    reject(d)
                }
            }).catch(e => {
                reject(e);
            })
        });

        
    }

    


}
